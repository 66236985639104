import React from 'react';
import logo from '../img/insurance321_logo_white.svg'
import Footer from '../components/Footer';
import Session321 from '../components/Session321'
import  "../styles/nomination.css"
import { Helmet } from "react-helmet"
import { Link } from 'gatsby'
import AboutUs from '../components/AboutUs'
import Nav from '../components/Nav'
import VideoElement from '../components/VideoElement'
import PlayButton from '../img/play-button.svg'
import CloseButton from '../img/CloseButton.svg'
import { FacebookShareButton, LinkedinShareButton, TwitterShareButton, } from "react-share";
import {  FacebookIcon, LinkedinIcon, TwitterIcon, } from "react-share";
const SmallBusinessThankYou = () => {
    const shareUrl = "https://insurance321.com/small-business/"
    const shareTitle = "Insurance321 Supports Small Business"
    const shareTag = "#cheerstosmallbusiness"
    const shareSummary = "Insurance321 has created a business support program. The program helps small businesses with marketing and technology at no cost to the business."
    const shareSource = "https://insurance321.com/small-business/"
    return (
        <div id="sb_container">
            <div id="video_overlay"><VideoElement /></div>
            <div id="small_business">
                <Helmet>
                    <title>Small Business Nomination Thank You</title>
                    <meta name="description" content="Insurance321 Small Business Support - Nomination Thank You Page"></meta>
                    <meta property='og:title' content='Small Business Nomination Thank You'/>
                    <meta property='og:image' content='https://insurance321.com/img/cheers-to-small-business-og-img.png'/>
                    <meta property='og:description' content='Insurance321 Small Business Support - Nomination Thank You Page'/>
                    <meta property='og:url' content='https://insurance321.com/small-business'/>
                    <Session321 />
                </Helmet>
                
                <div id="sb_nav_bar">
                    <div className="content-width">
                        <Link to="/"><div id="logo"><img src={logo} alt="insurance321 logo" /></div></Link>
                        <Nav />
                    </div>
                </div>
                <div id="close_button">
                    <img src={CloseButton} alt="Close button" width="50px" />
                </div>
                <div id="nomination_success_hero">
                    <div id="nomination_hero">
                        <div id="share_icons">
                            <FacebookShareButton url={shareUrl} title={shareTitle} hashTag={shareTag} summary={shareSummary}>
                                <FacebookIcon size={50} />
                            </FacebookShareButton>
                            <LinkedinShareButton url={shareUrl} title={shareTitle} source={shareSource} summary={shareSummary}>
                                <LinkedinIcon size={50}/>
                            </LinkedinShareButton>
                            <TwitterShareButton url={shareUrl} title={shareTitle} hashTag={shareTag} summary={shareSummary}>
                                <TwitterIcon size={50} />
                            </TwitterShareButton>

                        </div>
                        <div className="content-width">
                            <h1>Thank you for your nomination</h1>
                            <div id="play_button">
                                <img src={PlayButton} alt="Play button" width="70px" />
                                <h3>If your business is selected, they will receive a call by May 1st, 2021.</h3>
                            </div>
                        </div> 
                    </div>
                </div>
                <div id="form_container"></div>
                <div id="form_container_mobile"></div>
                <AboutUs />
                <Footer />
            </div>
        </div>
     );
}
 
export default SmallBusinessThankYou;